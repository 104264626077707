import { defineStore } from 'pinia'
import type { Deposit } from '~/utils/types/deposit'

export const useDepositStore = defineStore('deposit', {
    state: (): State => ({
        deposit: null,
        testData: [
            {
                first_name: 'Test',
                last_name: 'User',
                email: 'test@user.com',
                phone_number: '(425) 645-5423',
            },
            {},
            {},
        ],
    }),
    actions: {
        updateDeposit(data: Record<string, any>) {
            this.deposit = {
                ...(this.deposit ?? {}),
                ...data,
            }
        },

        reset() {
            this.deposit = null
        },
    },
})

interface State {
    deposit: Deposit | null
    testData: Record<string, any>[]
}
