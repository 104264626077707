export enum StoryblokVersion {
    DRAFT = 'draft',
    PUBLISHED = 'published',
}

export enum GatePageType {
    INVENTORY = 'inventory',
    TRADE = 'trade',
    FINANCE = 'finance',
}
