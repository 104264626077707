import { defineStore } from 'pinia'
import { useFetchAutoznetwork } from '~/composables/fetch/useFetchAutoznetwork'
import { useLocationStore } from '~/stores/location'
import { useVisitorStore } from '~/stores/visitor'
import { useDomainStore } from '~/stores/domain'
import { useStoryblokStore } from '~/stores/storyblok'
import type {
    Category,
    InventoryMerchandising,
    InventoryMeta,
    Vehicle,
} from '~/utils/types/inventory'
import { getSetting } from '~/utils/functions'
// import { useClientCacheOptions } from '~/composables/fetch/useClientCacheOptions'

export const useInventoryStore = defineStore('inventory', {
    state: (): State => ({
        vehicles: [],
        meta: {} as InventoryMeta,
        merchandising: null,
        srpCarfaxData: [],
        searchQuery: '',
        searchResults: [],
        isLoadingSearch: false,
        stockSearchValues: [],
        stockSearchResults: [],
        firstLoad: true,
        inventoryLoading: false,
        queryId: null,
        resultsPosition: null,
        sort: null,
        sortOptions: [
            { name: 'Relevance', translate_key: 'sort_relevance', sort: null },
            {
                name: 'Oldest Year',
                translate_key: 'sort_oldest_year',
                sort: 'year',
                sort_direction: 'asc',
            },
            {
                name: 'Newest Year',
                translate_key: 'sort_newest_year',
                sort: 'year',
                sort_direction: 'desc',
            },
            {
                name: 'Lowest Price',
                translate_key: 'sort_lowest_price',
                sort: 'price',
                sort_direction: 'asc',
            },
            {
                name: 'Highest Price',
                translate_key: 'sort_highest_price',
                sort: 'price',
                sort_direction: 'desc',
            },
        ],
    }),
    getters: {
        getFilteredSortOptions(state) {
            return (useDomainStore().shouldHidePrice ?? false)
                ? state.sortOptions.filter(
                      (item: Record<string, any>) => item.sort !== 'price',
                  )
                : state.sortOptions
        },
        getCurrentSortOption(state) {
            const sort: Record<string, any> | null = state.sort
            const filteredSortOptions: any[] = this
                .getFilteredSortOptions as any[]

            if (sort === null) {
                return filteredSortOptions[0]
            }

            return filteredSortOptions.find(
                (item: Record<string, any>) =>
                    item.sort === sort.sort &&
                    item.sort_direction === sort.sortDirection,
            )
        },
    },
    actions: {
        async fetchInventory(
            params: Record<string, string | number | undefined> = {},
            category: Category | null = null,
            firstLoad: boolean = false,
            ignoreQueryId: boolean = false,
        ): Promise<void> {
            if (firstLoad) {
                this.firstLoad = false
            }

            this.inventoryLoading = true
            params = await this.addFingerprintToQuery(params)

            if (category) {
                params.categories = category.name
            }

            params.include = 'assets'

            const { data: res } = await useFetchAutoznetwork(
                '/inventory/search',
                {
                    query: this.addDomainQueryParams(params),
                    fetch: true,
                    // ...useClientCacheOptions(5 * 60),
                },
            )

            if (res.value) {
                this.vehicles = res.value.data
                this.meta = res.value.meta
                this.merchandising = res.value.merchandising

                if (!ignoreQueryId) {
                    this.queryId = res.value.queryId
                }

                const carfaxIsEnabled = getSetting(
                    useDomainStore().autoZnetworkSettings,
                    'vendor.carfax.enabled',
                )

                if (carfaxIsEnabled && useStoryblokStore().srpHistoryEnabled) {
                    if (this.vehicles.length > 0) {
                        this.getCarfaxForVehicles()
                    } else {
                        this.srpCarfaxData = []
                    }
                }
            }

            this.inventoryLoading = false
        },

        async getCategory(category: string) {
            const { data } = await useFetchAutoznetwork(
                `/vehicle/categories/${category}`,
            )

            return data.value
        },

        async fetchSearchResults(query: string) {
            this.searchQuery = query

            const params = await this.addFingerprintToQuery({
                query,
                limit: 10,
            })

            const { data } = await useFetchAutoznetwork('/inventory/search', {
                query: this.addDomainQueryParams(params),
            })

            this.searchResults = data.value.data
            this.isLoadingSearch = false
        },

        async getRelatedVehicles(id: number) {
            return await useFetchAutoznetwork(`/inventory/${id}/related`, {
                query: { limit: 4 },
                fetch: true,
            })
        },

        async fetchHomePageInventory(params = {}) {
            params = await this.addFingerprintToQuery(params)

            return await useFetchAutoznetwork('/inventory/search', {
                query: this.addDomainQueryParams(params),
                fetch: true,
            })
        },

        getCarfaxForVehicles(
            vehicleIds: number[] | null = null,
            returnPromise: boolean = false,
        ) {
            if (vehicleIds === null) {
                vehicleIds = this.vehicles.map((vehicle: Vehicle) => vehicle.id)
            }

            const promise = useFetchAutoznetwork('/inventory/carfax', {
                method: 'post',
                body: {
                    vehicle_ids: vehicleIds,
                },
                fetch: true,
            })

            if (returnPromise && vehicleIds !== null) {
                return promise
            }

            promise.then((data: any) => {
                this.srpCarfaxData = data.data.value?.data ?? []
            })
        },

        getCarfaxForVehiclesWithPromise(vehicleIds: number[]) {
            return (
                this.getCarfaxForVehicles(vehicleIds, true) ?? Promise.resolve()
            )
        },

        addDomainQueryParams(params: object, withPrecision: boolean = true) {
            const domainStore = useDomainStore()

            return {
                ...params,
                ...(domainStore.getDefaultInventoryQuery ?? {}),
                ...{
                    precision: withPrecision
                        ? !domainStore.geoSettings.disabled &&
                          domainStore.geoSettings.precision
                            ? domainStore.geoSettings.precision
                            : undefined
                        : undefined,
                },
            }
        },

        clearAllStockValues() {
            this.stockSearchValues = []
            this.stockSearchResults = []
        },

        // removeStockSearchResult(stockNumber: string) {
        //     this.stockSearchValues = remove(this.stockSearchValues, (n) => {
        //         return n !== stockNumber
        //     })
        //     this.stockSearchResults = remove(this.stockSearchResults, (n) => {
        //         return n.stock !== stockNumber
        //     })
        // },

        // addStockSearchResult(stockNumber: string) {
        //     if (!this.stockSearchValues.includes(stockNumber)) {
        //         this.stockSearchValues.push(stockNumber)
        //     }
        //
        //     this.fetchStockSearchResults()
        // },

        updateSort(sort: Record<string, any> | null) {
            this.sort = sort
        },

        // async fetchStockSearchResults() {
        //     const params = await this.addFingerprintToQuery({})
        //
        //     try {
        //         const data = await axios.get('/tenant-api/inventory/search', {
        //             params,
        //         })
        //         this.stockSearchResults = data.data.data
        //     } catch (error) {
        //         console.log(error)
        //     }
        // },

        clearSearchResults() {
            this.searchQuery = ''
            this.searchResults = []
        },

        // async fetchVehicle(id: number) {
        //     try {
        //         const data = await axios.get(`/tenant-api/inventory/${id}`)
        //         this.vehicle = data.data
        //     } catch (error) {
        //         console.log(error)
        //     }
        // },

        // async fetchRelatedVehicles(id, params = {}) {
        //     return axios.get(`/tenant-api/inventory/${id}/related`, {
        //         params,
        //     })
        // },

        async addFingerprintToQuery(params: any) {
            const locationStore = useLocationStore()
            const domainStore = useDomainStore()
            const visitorStore = useVisitorStore()

            if (
                locationStore.geo === null ||
                visitorStore.fingerprint === null
            ) {
                await this.waitForGeoAndFingerprint(locationStore, visitorStore)
            }

            return Object.assign(JSON.parse(JSON.stringify(params)), {
                visitor_id: visitorStore.getVisitorId,
                coordinates: !domainStore.geoSettings.disabled
                    ? (locationStore.getCurrentLocationCoordinatesString ??
                      undefined)
                    : undefined,
                radius: undefined,
                precision: undefined,
            })
        },

        async waitForGeoAndFingerprint(locationStore: any, visitorStore: any) {
            const startTime = new Date().getTime()

            while (true) {
                if (
                    locationStore.geo !== null &&
                    visitorStore.fingerprint !== null
                ) {
                    break
                }
                if (new Date().getTime() > startTime + 3000) {
                    break
                }

                await new Promise((resolve) => setTimeout(resolve, 500))
            }
        },

        clearQueryData() {
            this.queryId = null
            this.resultsPosition = null
        },
    },
})

interface State {
    vehicles: Vehicle[]
    meta: InventoryMeta
    merchandising: InventoryMerchandising | null
    srpCarfaxData: any[]
    searchQuery: string
    searchResults: any[]
    isLoadingSearch: boolean
    stockSearchValues: any[]
    stockSearchResults: any[]
    firstLoad: boolean
    inventoryLoading: boolean
    queryId: string | null
    resultsPosition: number | null
    sort: Record<string, any> | null
    sortOptions: SortObject[]
}

interface SortObject {
    name: string
    sort: string | null
    sort_direction?: string
    translate_key: string
}
