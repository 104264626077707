import type { Dayjs } from 'dayjs'
import dayjs from 'dayjs'
import type {
    ImageParamOptions,
    Vehicle,
    VehicleAsset,
} from '~/utils/types/inventory'
import { DayOfTheWeek, Department } from '~/utils/types/location/enums'
import { ASCDepartment } from '~/utils/types/asc/enums'
import { VehicleAssetType } from '~/utils/types/inventory/enums'

export function convertPhoneToHuman(
    number: string | null,
    friendly: boolean = true,
) {
    if (number === null) {
        return ''
    } else {
        return number
            .replace('+1', '')
            .replace(
                /(\d{3})(\d{3})(\d{4})/,
                friendly ? '($1) $2-$3' : '$1-$2-$3',
            )
    }
}

export function chunkArray(arr: any[], size: number) {
    const returnArr = []

    for (let i = 0; i < arr.length; i += size) {
        returnArr.push(arr.slice(i, i + size))
    }

    return returnArr
}

export function pluralize(
    count: number | string,
    noun: string,
    suffix: string = 's',
) {
    if (typeof count === 'string') {
        count = parseInt(count)
    }

    return `${count} ${noun}${count !== 1 ? suffix : ''}`
}

export function formatPossessive(word: string) {
    return word.endsWith('s') ? `${word}'` : `${word}'s`
}

export function objectToQuery(obj: any, allowEmptyValues: boolean = false) {
    return Object.keys(obj)
        .filter((key) => allowEmptyValues || obj[key])
        .map((key) => `${key}=${obj[key]}`)
        .join('&')
}

export function queryToObject(str: string) {
    return Object.fromEntries(new URLSearchParams(str))
}

export function formatObjectErrors(errors: any[][] | any[]) {
    const returnObj: any = {}

    Object.keys(errors).forEach((key: any) => {
        returnObj[key] = Array.isArray(errors[key])
            ? errors[key][0]
            : errors[key]
    })

    return returnObj
}

export function getSetting(settings: any, key: string) {
    return key
        .split('.')
        .reduce((a: any, b: any) => (a && a[b] ? a[b] : null), settings)
}

export const daysOfTheWeek: DayOfTheWeek[] = [
    DayOfTheWeek.SUNDAY,
    DayOfTheWeek.MONDAY,
    DayOfTheWeek.TUESDAY,
    DayOfTheWeek.WEDNESDAY,
    DayOfTheWeek.THURSDAY,
    DayOfTheWeek.FRIDAY,
    DayOfTheWeek.SATURDAY,
    DayOfTheWeek.SUNDAY,
]

export function currentWeekDay(dayNum: number): string {
    return daysOfTheWeek[dayNum]
}

export function hoursOfOperationForDay(
    weekDay: string | number,
    hoursOfOperation: any,
) {
    if (!hoursOfOperation) {
        return null
    }
    const currentDayOfWeek: string =
        typeof weekDay === 'string' ? weekDay : currentWeekDay(weekDay)

    return hoursOfOperation[currentDayOfWeek]
}

export function groupHoursOfOperation(
    hours: any,
    shortenDays: boolean = false,
) {
    const { t } = useI18n()

    const baseDay = hours.Monday
    const saturday = hours.Saturday
    const sunday = hours.Sunday
    let saturdayIsDifferent: boolean = false

    const daySchema: Record<string, any> = {
        mondayFriday: shortenDays ? 'Mo-Fr' : `${t('monday')}-${t('friday')}`,
        mondaySaturday: shortenDays
            ? 'Mo-Sa'
            : `${t('monday')}-${t('saturday')}`,
        mondaySunday: shortenDays ? 'Mo-Su' : `${t('monday')}-${t('sunday')}`,
        saturdaySunday: shortenDays
            ? 'Sa-Su'
            : `${t('saturday')}-${t('sunday')}`,
        saturday: shortenDays ? 'Sa' : t('saturday'),
        sunday: shortenDays ? 'Su' : t('sunday'),
    }

    const returnObj: any = {
        [daySchema.mondayFriday]: baseDay,
    }

    if (saturday.open === baseDay.open && saturday.close === baseDay.close) {
        returnObj[daySchema.mondaySaturday] = baseDay
        delete returnObj[daySchema.mondayFriday]
    } else {
        saturdayIsDifferent = true
        returnObj[daySchema.saturday] = saturday
    }

    if (
        !saturdayIsDifferent &&
        sunday.open === baseDay.open &&
        sunday.close === baseDay.close
    ) {
        returnObj[daySchema.mondaySunday] = baseDay
        delete returnObj[daySchema.mondaySaturday]
    } else if (
        saturdayIsDifferent &&
        saturday.open === sunday.open &&
        saturday.close === sunday.close
    ) {
        returnObj[daySchema.saturdaySunday] = saturday
        delete returnObj[daySchema.saturday]
    } else {
        returnObj[daySchema.sunday] = sunday
    }

    return returnObj
}

export function isDST() {
    const date = new Date()

    const jan = new Date(date.getFullYear(), 0, 1).getTimezoneOffset()
    const jul = new Date(date.getFullYear(), 6, 1).getTimezoneOffset()

    return Math.max(jan, jul) !== date.getTimezoneOffset()
}

export function convertTimezoneToAbbr(timezone: string) {
    const timezoneIndex = isDST() ? 1 : 0

    const timezoneObj: any = {
        'America/Los_Angeles': ['PST', 'PDT'],
        'America/Phoenix': ['MST'],
        'America/Boise': ['MST', 'MDT'],
        'America/Chicago': ['CST', 'CDT'],
        'America/New_York': ['EST', 'EDT'],
    }

    return timezoneObj[timezone]
        ? timezoneObj[timezone].length === 1
            ? timezoneObj[timezone][0]
            : timezoneObj[timezone][timezoneIndex]
        : null
}

export function ucwords(str: string): string {
    return (str + '')
        .toLowerCase()
        .replace(/^([a-z])|\s+([a-z])/g, (s) => s.toUpperCase())
}

export function formatSearchTerm(searchTerm: string) {
    searchTerm = searchTerm.replace('_', ', ')

    if (searchTerm.includes(' > ')) {
        searchTerm = searchTerm.split(' > ')[1]
    }

    return searchTerm
}

export function stripWhitespace(string: string) {
    const regex = /(\([^)]*\))|(\s+)/g

    return string.replace(regex, (group1) => group1 || '')
}

export function inventoryImageUrl(image: string, settings: ImageParamOptions) {
    if (!settings) {
        return image
    }

    const paramArr = []

    if (settings.maxWidth) {
        paramArr.push(`mw_${settings.maxWidth}`)
    } else if (settings.width) {
        paramArr.push(`w_${settings.width}`)
    }

    if (settings.height) {
        paramArr.push(`h_${settings.height}`)
    }

    if (settings.format) {
        paramArr.push(`format_${settings.format}`)
    }

    if (settings.overlay) {
        paramArr.push(settings.overlay)
    }

    if (settings.fit) {
        paramArr.push(`fit_${settings.fit}`)
    }

    if (settings.position) {
        paramArr.push(`pos_${settings.position}`)
    }

    if (paramArr.length === 0) {
        return image
    }

    const start: string = image.substring(0, image.lastIndexOf('/') + 1)
    const paramStr: string = paramArr.join(',')
    const end: string = image.substring(
        image.lastIndexOf('/') + 1,
        image.length,
    )

    return `${start}${paramStr}/${end}`
}

export function formatMaskedValue(value: string | number): number {
    return parseInt(value.toString().replace(/,/g, ''))
}

export function fractionToDecimal(string: string) {
    let total: number = 0
    const numSplit: string[] = string.split(' ')

    for (let i: number = 0; i < numSplit.length; i++) {
        const numberStr: string = numSplit[i]

        if (numberStr.includes('/')) {
            const fractionSplit: string[] = numberStr.split('/')
            total += parseInt(fractionSplit[0]) / parseInt(fractionSplit[1])
        } else {
            total += parseInt(numberStr)
        }
    }

    return total
}

export function addServerValidationErrors(
    form$: any,
    errors: Record<string, string[]>,
): void {
    Object.keys(errors).forEach((key: string): void => {
        form$.value.el$(key)?.messageBag.append(errors[key][0])
    })
}

export function clearServerValidationErrors(form$: any): void {
    Object.keys(form$.value.data).forEach((key: string): void => {
        form$.value.el$(key)?.messageBag.clear()
    })
}

export function formatRangeValues(
    value: string,
    separator: string = '-',
): Record<'min' | 'max', number> {
    const splitArr: string[] = value.split(separator)

    return {
        min: parseInt(splitArr[0]),
        max: parseInt(splitArr[1]),
    }
}
export function formatThousands(num: number) {
    return num < 1000 ? num : `${Math.round((num / 1000) * 10) / 10}K`
}

export function blokId(blok: Record<string, any>) {
    return `dm-blok-${blok._uid}`
}

export function randomString(length: number) {
    const characters: string =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    let result: string = ' '
    const charactersLength: number = characters.length

    for (let i: number = 0; i < length; i++) {
        result += characters.charAt(
            Math.floor(Math.random() * charactersLength),
        )
    }

    return result
}

export function formatTimeWithMinuteCheck(time: string): string {
    const timeObj: Dayjs = dayjs(time, 'HH:mm')
    return timeObj.format(timeObj.minute() === 0 ? 'hA' : 'h:mmA')
}

export function parseLocaleTemplates(
    message: string,
    schema: Record<string, any>,
): string {
    Object.keys(schema).forEach((search: string) => {
        message = message.replaceAll(`[${search}]`, schema[search])
    })

    return message
}

export function getMainVehicleMuxVideoAsset(vehicle: Vehicle) {
    if (!vehicle.assets) {
        return null
    }

    const assets = vehicle.assets.filter(
        (asset: VehicleAsset) =>
            asset.type === VehicleAssetType.VIDEO &&
            asset.active &&
            asset.display &&
            asset.mux_url !== null,
    )

    return assets.length > 0 ? assets[0] : null
}

export function convertLocationDepartmentToASCDepartment(
    locationDepartment: string | Department | null,
): ASCDepartment {
    if (!locationDepartment) {
        return ASCDepartment.UNKNOWN
    }

    let ascDepartment: ASCDepartment

    switch (locationDepartment) {
        case Department.SALES:
            ascDepartment = ASCDepartment.SALES
            break
        case Department.SERVICE:
            ascDepartment = ASCDepartment.SERVICE
            break
        case Department.PARTS:
            ascDepartment = ASCDepartment.PARTS
            break
        case Department.DETAIL:
            ascDepartment = ASCDepartment.BODY_SHOP
            break
        default:
            ascDepartment = ASCDepartment.UNKNOWN
    }

    return ascDepartment
}
