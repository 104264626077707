import { defineStore } from 'pinia'
import type { RuntimeConfig } from 'nuxt/schema'
import type {
    Gate,
    StoryblokLeadButton,
    StoryblokNitroParams,
    StoryblokSRPSettings,
    StoryblokVDPSettings,
} from '~/utils/types/storyblok'
import { useFetchStoryblokNitro } from '~/composables/fetch/useFetchStoryblokNitro'
import { assignStoryblokSettings } from '~/composables/storyblok/assignStoryblokSettings'
import gateRouteSchema from '~/utils/gateRouteSchema'
import { GatePageType, StoryblokVersion } from '~/utils/types/storyblok/enums'

export const useStoryblokStore = defineStore('storyblok', {
    state: (): State => ({
        token: null,
        version: StoryblokVersion.DRAFT,
        settings: null,
        settingsLoaded: false,
        vdpSettings: null as StoryblokVDPSettings | null,
        vdpSettingsLoaded: false,
        navMobileSidebarOpen: false,
        headerFallback: {
            buttons: [
                {
                    name: 'Shop',
                    url: '',
                    menu: [
                        {
                            name: 'All Vehicles',
                            title: 'Search for your perfect truck, SUV or car.',
                            url: '/inventory',
                        },
                        {
                            name: 'Trucks',
                            title: 'Check out our selection of Unique Trucks.',
                            url: '/inventory?body_style=Truck',
                        },
                    ],
                },
                { name: 'Sell/Trade', url: '/sell', menu: [] },
                { name: 'Finance', url: '/finance-application', menu: [] },
                { name: 'Company', url: '/company', menu: [] },
                { name: 'Contact', url: '/contact', menu: [] },
            ],
        },
        footerFallback: [
            {
                name: 'Inventory',
                url: '',
                menu: [
                    {
                        name: 'Cars',
                        url: '/inventory?body_style=Sedan,Coupe,Hatchback,Convertible',
                    },
                    { url: 'Trucks', href: '/inventory?body_style=Truck' },
                    { url: 'SUVs', href: '/inventory?body_style=SUV' },
                ],
            },
            {
                name: 'Support',
                url: '',
                menu: [
                    { name: 'Sell or Trade', url: '/sell' },
                    { name: 'Financing', url: '/finance-application' },
                ],
            },
            {
                name: 'Company',
                url: '',
                menu: [
                    { name: 'Locations', href: '/locations' },
                    { name: 'Contact', href: '/contact' },
                    { name: 'Blog', href: '/blog' },
                ],
            },
            {
                name: 'Legal',
                url: '',
                menu: [{ name: 'Privacy Policy', href: '/privacy-policy' }],
            },
        ],
        currentGate: null,
        gateModalOpen: false,
        gateOpened: !!useCookie('gto').value,
    }),
    getters: {
        getHeaderLayout(state) {
            return state.settings?.header_layout || 'Default'
        },
        getNavigationHeader(state) {
            return state.settings?.header_navigation
        },
        getNavigationFooter(state) {
            return state.settings?.footer_navigation || state.footerFallback
        },
        getLogo(state) {
            return state.settings?.logo ? state.settings.logo[0] : null
        },
        callForThePriceText(state) {
            return state.settings?.call_for_price_text ?? null
        },
        srpSettings(state): StoryblokSRPSettings | null {
            const srpSettings = state.settings?.srp_settings ?? []
            return srpSettings.length === 1 ? srpSettings[0] : null
        },
        srpHistoryEnabled(): boolean {
            return this.srpSettings?.history_enabled ?? false
        },
        srpCTAButtons(): StoryblokLeadButton[] {
            return this.srpSettings?.cta_lead_buttons ?? []
        },
        gating(state) {
            const gatingArr = state.settings?.gating ?? []
            return gatingArr.length === 1 ? gatingArr[0] : null
        },
        gatingEnabled(): boolean {
            return this.gating?.enabled ?? false
        },
        gates(): Gate[] {
            return this.gating?.gates ?? []
        },
        vdpCapitalOneCalculatorEnabled(): boolean {
            return this.vdpSettings?.capital_one_calculator_enabled ?? false
        },
    },
    actions: {
        async loadSettings(
            type: StoryblokSettingsType,
            params: StoryblokNitroParams,
        ) {
            await useFetchStoryblokNitro({
                ...params,
                ...{
                    slug: type,
                },
            })
                .then((res: any) => {
                    if (res.story) {
                        if (type === StoryblokSettingsType.SETTINGS) {
                            this.settings = res.story.content
                        } else if (
                            type === StoryblokSettingsType.VDP_SETTINGS
                        ) {
                            this.vdpSettings = res.story.content
                        }
                    }

                    if (type === StoryblokSettingsType.SETTINGS) {
                        this.settingsLoaded = true
                    } else if (type === StoryblokSettingsType.VDP_SETTINGS) {
                        this.vdpSettingsLoaded = true
                    }
                })
                .catch(() => {
                    if (type === StoryblokSettingsType.SETTINGS) {
                        this.settingsLoaded = true
                    } else if (type === StoryblokSettingsType.VDP_SETTINGS) {
                        this.vdpSettingsLoaded = true
                    }
                })
        },
        async resetAndFetchStoryblokSettings(
            $config: RuntimeConfig,
            locale: string,
            query: Record<string, any>,
        ) {
            this.settingsLoaded = false
            this.vdpSettingsLoaded = false

            await assignStoryblokSettings(
                $config,
                locale,
                query,
                StoryblokSettingsType.SETTINGS,
            )
            await assignStoryblokSettings(
                $config,
                locale,
                query,
                StoryblokSettingsType.VDP_SETTINGS,
            )
        },
        resetNavMobileSidebarOpen() {
            this.navMobileSidebarOpen = false
        },
        checkForGate(page: string, override: string | null) {
            const overrideGateType: GatePageType | null = override
                ? (Object.values(GatePageType).find(
                      (v: GatePageType): boolean => override === v,
                  ) ?? null)
                : null
            const gatePageType: GatePageType | null =
                gateRouteSchema[page] ?? null

            if (gatePageType) {
                const overrideGate: Gate | null = overrideGateType
                    ? (this.gates.find(
                          (gate: Gate) => gate.page === overrideGateType,
                      ) ?? null)
                    : null
                let gate: Gate | null =
                    this.gates.find(
                        (gate: Gate) => gate.page === gatePageType,
                    ) ?? null

                if (overrideGate && gate && gate.allow_override) {
                    gate = overrideGate
                }

                if (gate) {
                    this.currentGate = gate
                    this.gateModalOpen = true
                } else {
                    this.resetGate()
                }
            } else {
                this.resetGate()
            }
        },
        resetGate() {
            this.currentGate = null
            this.closeGateModal()
        },
        openGate(closeModal: boolean = true) {
            useCookie('gto').value = 'true'
            this.gateOpened = true

            if (closeModal) {
                this.closeGateModal()
            }
        },
        closeGateModal() {
            this.gateModalOpen = false
        },
    },
})

interface State {
    token: string | null
    version: StoryblokVersion | undefined
    settings: Record<string, any> | null
    settingsLoaded: boolean
    vdpSettings: StoryblokVDPSettings | null
    vdpSettingsLoaded: boolean
    navMobileSidebarOpen: boolean
    headerFallback: Record<'buttons', Record<string, any>[]>
    footerFallback: Record<string, any>[]
    currentGate: Gate | null
    gateModalOpen: boolean
    gateOpened: boolean
}

export enum StoryblokSettingsType {
    SETTINGS = 'settings',
    VDP_SETTINGS = 'vdp-settings',
}
