export default defineNuxtRouteMiddleware((to) => {
    // skip middleware on server
    if (import.meta.server) return

    const eventStore = useEventStore()
    const storyblokStore = useStoryblokStore()
    const searchStore = useSearchStore()
    const modalStore = useModalStore()
    const { isCrawler } = useDevice()

    // event
    eventStore.resetDataLayer()
    eventStore.resetASCPageType()

    // search
    if (searchStore.isOpen) {
        searchStore.clearSearchResults()
        searchStore.closeSearch()
    }

    if (modalStore.open) {
        modalStore.closeModal()
    }

    // Storyblok
    storyblokStore.resetNavMobileSidebarOpen()
    storyblokStore.resetGate()

    if (
        storyblokStore.gatingEnabled &&
        !storyblokStore.gateOpened &&
        !isCrawler
    ) {
        storyblokStore.checkForGate(
            to.path,
            to.query.gate ? to.query.gate.toString() : null,
        )
    }
})
