import { defineStore } from 'pinia'
import type {
    AutoZnetworkEnterprise,
    AutoZnetworkEntity,
    AutoZnetworkOrganization,
    Domain,
    Space,
} from '~/utils/types/global'
import { buildAnalyticsSettings } from '~/composables/asc/utility/buildAnalyticsSettings'
import { getSetting } from '~/utils/functions'
import type { DomainEntityType } from '~/utils/types/global/enums'

export const useDomainStore = defineStore('domain', {
    state: (): State => ({
        domain: {} as Domain,
        entityType: null,
    }),
    getters: {
        getAutoZnetworkOrgId(state): number | null {
            return state.domain.autoznetwork_organization_id ?? null
        },
        getAutoZnetworkEnterpriseId(state): number | null {
            return state.domain.autoznetwork_enterprise_id ?? null
        },
        autoZnetworkOrganization(state): AutoZnetworkOrganization | null {
            return state.domain.autoz_organization ?? null
        },
        autoZnetworkEnterprise(state): AutoZnetworkEnterprise | null {
            return state.domain.autoz_enterprise ?? null
        },
        autoZnetworkEntity(state): AutoZnetworkEntity {
            return state.domain.autoz_entity
        },
        autoZnetworkSettings(): Record<string, any> {
            return (
                this.autoZnetworkEnterprise?.settings ??
                this.autoZnetworkOrganization?.settings ??
                {}
            )
        },
        facetAttributes(state): string[] {
            return state.domain.facet_attributes
        },
        space(state): Space | undefined {
            return state.domain.space
        },
        getDefaultInventoryQuery(state): Record<any, any> {
            return state.domain.default_inventory_query
        },
        getInventoryPrecision(): number | null {
            return getSetting(this.settings, 'geo.precision') ?? null
        },
        settings(state): Record<any, any> {
            return state.domain.settings ?? {}
        },
        geoSettings() {
            const geoSettings = getSetting(this.settings, 'geo') ?? null

            return {
                disabled: (this.isGroupSite || geoSettings?.disabled) ?? false,
                defaultLocationId: geoSettings?.defaultLocationId ?? null,
                precision: geoSettings?.precision ?? null,
            }
        },
        getAnalyticsSettings(): Record<string, any> | null {
            const analytics: Record<string, any> | null =
                this.settings.vendor?.analytics ?? null

            if (!analytics) {
                return null
            }

            return buildAnalyticsSettings(analytics)
        },
        measurementIds(): string[] | null {
            return (
                getSetting(
                    this.settings,
                    'vendor.analytics.google.analytics',
                ) ?? null
            )
        },
        zarazIsEnabled() {
            return (
                getSetting(this.settings, 'vendor.analytics.zaraz.enabled') ??
                false
            )
        },
        inventorySettings(): InventorySettings {
            const inventorySettings: Record<string, any> = getSetting(
                this.settings,
                'inventory',
            )

            return {
                overlay: inventorySettings?.images?.overlay ?? null,
                hidePrices: inventorySettings?.hidePrices ?? false,
                unlockPrices: inventorySettings?.unlockPrices ?? false,
                unlockPriceType: inventorySettings?.unlockPriceType ?? null,
                srpGallery: inventorySettings?.srp?.gallery ?? false,
                disableImageCrop:
                    inventorySettings?.images?.disableCrop ?? false,
            } as InventorySettings
        },
        gtagIsEnabled() {
            return (
                !this.zarazIsEnabled &&
                this.measurementIds &&
                this.measurementIds.length > 0
            )
        },
        pricesHidden(): boolean {
            return getSetting(this.settings, 'inventory.hidePrices') ?? false
        },
        priceUnlock(): boolean {
            return getSetting(this.settings, 'inventory.unlockPrices') ?? false
        },
        shouldHidePrice(): boolean {
            return this.pricesHidden || this.priceUnlock
        },
        isGroupSite(): boolean {
            return (
                this.autoZnetworkEnterprise !== null &&
                !this.autoZnetworkEnterprise.physical
            )
        },
        cargurusEnabled(): boolean {
            return getSetting(this.settings, 'inventory.cargurus.enabled')
        },
        oemIncentivesEnabled(): boolean {
            return getSetting(this.settings, 'oem.incentives.enabled') ?? false
        },
        complyAuto(): ComplyAuto {
            const complyAutoSettings: Record<string, any> = getSetting(
                this.settings,
                'complyAuto',
            )

            return {
                enabled: complyAutoSettings?.enabled ?? false,
                ids: {
                    cookieBanner: complyAutoSettings?.cookieBanner ?? null,
                    privacyPolicy: complyAutoSettings?.privacyPolicy ?? null,
                    requestPortal: complyAutoSettings?.requestPortal ?? null,
                },
            }
        },
        honkr(): Honkr {
            const honkr: Record<string, any> | null =
                getSetting(this.settings, 'honkr') ?? null

            const amounts = honkr?.deposits?.amounts ?? {}

            return {
                organizationId: honkr?.organizationId ?? null,
                deposits: {
                    enabled: honkr?.deposits?.enabled ?? false,
                    amounts: {
                        standard: amounts.standard ?? null,
                        alternative: amounts.alternative ?? null,
                    },
                },
            }
        },
    },
    actions: {
        setDomain(domain: Domain, entityType: DomainEntityType | null) {
            this.domain = domain
            this.entityType = entityType
        },
    },
})

interface State {
    domain: Domain
    entityType: DomainEntityType | null
}

interface InventorySettings {
    overlay: string | null
    hidePrices: boolean
    unlockPrices: boolean
    unlockPriceType?: 'phone_number' | 'email' | undefined
    srpGallery: boolean
    disableImageCrop: boolean
}

interface ComplyAuto {
    enabled: boolean
    ids: ComplyAutoIds
}

interface ComplyAutoIds {
    cookieBanner: string | null
    privacyPolicy: string | null
    requestPortal: string | null
}

interface Honkr {
    organizationId: number | null
    deposits: DomainDeposits
}

interface DomainDeposits {
    enabled: boolean
    amounts: DomainDepositAmounts
}

interface DomainDepositAmounts {
    standard?: number | null
    alternative?: number | null
}
