import { defineStore } from 'pinia'
import { useFetchDealerMe } from '~/composables/fetch/useFetchDealerMe'
import type { Geo, Location } from '~/utils/types/location'

export const useLocationStore = defineStore('location', {
    state: (): State => ({
        geo: null,
        currentLocation: null,
        locations: [],
        slideOverIsOpen: false,
        browserCoordinatesIsLoading: false,
    }),
    getters: {
        isActive: (state) => (location: Location) => {
            return location.id === state.currentLocation?.id
        },
        getZipcode(state) {
            return state.geo?.zipcode
        },
        getCoordinates(state) {
            return state.geo?.coordinates
        },
        getCoordinatesString(state) {
            return state.geo
                ? state.geo.latitude + ',' + state.geo.longitude
                : null
        },
        getCurrentLocation(state) {
            return state.currentLocation
        },
        getCurrentLocationSlug(state) {
            return state.currentLocation
                ? `/locations/${state.currentLocation.address.state}/${state.currentLocation.address.city}/${state.currentLocation.slug}`.toLowerCase()
                : null
        },
        getCurrentLocationCoordinatesString(state) {
            return state.currentLocation &&
                state.currentLocation.coordinates !== null
                ? state.currentLocation.coordinates.latitude +
                      ',' +
                      state.currentLocation.coordinates.longitude
                : null
        },
        getLocations:
            (state) =>
            (excludeCurrentLocation = false) => {
                if (excludeCurrentLocation) {
                    return state.locations.filter(
                        (location: Location) =>
                            location.id !== state.currentLocation?.id,
                    )
                }

                return state.locations
            },
        getLocation: (state) => (locationId: any) => {
            return state.locations.filter(
                (location: Location) => location.id === locationId,
            )[0]
        },
        getLocationCount(state) {
            return state.locations?.length ?? 0
        },
        getCurrentLocationCityState(state) {
            if (state.currentLocation) {
                return `${state.currentLocation.address.city}, ${state.currentLocation.address.state}`
            }
        },
        getCurrentLocationStoreNumber(state) {
            return state.currentLocation?.phone_number ?? null
        },
        getCurrentLocationSalesNumber(state) {
            return getSetting(
                state.currentLocation,
                'settings.sales.phone_number',
            )
        },
        getCurrentLocationServiceNumber(state) {
            return getSetting(
                state.currentLocation,
                'settings.service.phone_number',
            )
        },
        getCurrentLocationPartsNumber(state) {
            return getSetting(
                state.currentLocation,
                'settings.parts.phone_number',
            )
        },
        getCurrentLocationDepartmentNumbers() {
            return {
                sales: this.getCurrentLocationSalesNumber,
                service: this.getCurrentLocationServiceNumber,
                parts: this.getCurrentLocationPartsNumber,
            }
        },
        getCurrentLocationFullAddress(state) {
            return state.currentLocation
                ? `${state.currentLocation.address.street_address} ${state.currentLocation.address.city}, ${state.currentLocation.address.state} ${state.currentLocation.address.zip}`
                : null
        },
    },
    actions: {
        openSlideOver() {
            this.slideOverIsOpen = true
        },
        closeSlideOver() {
            this.slideOverIsOpen = false
        },
        getBrowserCoordinates() {
            if (navigator.geolocation) {
                // Getting Browser Location
                this.browserCoordinatesIsLoading = true
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        this.clearCurrentLocation()

                        this.fetchLocations({
                            latitude: position.coords.latitude,
                            longitude: position.coords.longitude,
                        }).then(() => {
                            this.closeSlideOver()
                        })
                    },
                    (error) => {
                        console.log(error)
                    },
                )
                // Clear Getting Browser Location
                this.browserCoordinatesIsLoading = false
            } else {
                console.log('Geolocation is not supported by this browser.')
            }
        },
        getLocationsByZipcode(zipCode: string) {
            const cookieZipcode = useCookie('zipcode')

            this.clearCurrentLocation()
            this.fetchLocations({
                zipCode,
            }).then(() => {
                cookieZipcode.value = zipCode
                this.closeSlideOver()
            })
        },
        async bootLocations() {
            const locationStore = useLocationStore()
            const cookieZipcode = useCookie('zipcode')

            const query: Record<string, any> = {
                zipcode:
                    cookieZipcode.value ??
                    locationStore.geo?.zipcode ??
                    undefined,
            }

            if (locationStore.geo) {
                query.latitude = locationStore.geo.latitude
                query.longitude = locationStore.geo.longitude
            }

            await this.fetchLocations(query)
        },
        async fetchLocations(
            query: Record<string, string | number | undefined>,
        ) {
            const cookie = useCookie('dm_current_location')

            query.ip_address = useGlobalStore().clientIp ?? undefined
            query.default_location_id =
                useDomainStore().geoSettings.defaultLocationId ?? undefined

            const { data: locations } = await useFetchDealerMe(
                '/locations/geo',
                {
                    query,
                },
            )

            this.assignLocations(locations.value)
            this.assignCurrentLocation(null, cookie)
        },
        assignGeo(geo: Geo) {
            this.geo = geo
        },
        assignLocations(locations: Location[]) {
            this.locations = locations
        },
        clearCurrentLocation() {
            useCookie('dm_current_location').value = null
        },
        getLocationFullAddress(location: Location) {
            return `${location.address.street_address} ${location.address.city}, ${location.address.state} ${location.address.zip}`
        },
        assignCurrentLocation(location: Location | null = null, cookie: any) {
            let currentLocation: Location | null

            if (location) {
                currentLocation = location
            } else {
                // const currentId = cookie.value ?? ''
                // currentLocation = null
                //
                // if (currentId) {
                //     currentLocation =
                //         this.locations?.find(
                //             (location: Location) =>
                //                 location.id === parseInt(currentId),
                //         ) ?? null
                // }
                //
                // if (!currentLocation && this.locations) {
                //     currentLocation =
                //         this.locations.length > 0 ? this.locations[0] : null
                // }

                currentLocation =
                    this.locations.length > 0 ? this.locations[0] : null
            }

            if (currentLocation) {
                this.currentLocation = currentLocation
                cookie.value = currentLocation.id.toString()

                this.assignGeo(currentLocation.geo)
            }
        },
        getLocationGoogleMapsAddress(location: Location) {
            const urlString = location.google_place_id
                ? `query=${location.name}&query_place_id=${location.google_place_id}`
                : location.coordinates
                  ? `query=${location.coordinates.latitude},${location.coordinates.longitude}`
                  : `query=${location.address.street_address} ${location.address.city}, ${location.address.state} ${location.address.zip}`

            return `https://www.google.com/maps/search/?api=1&${urlString}`
        },
    },
})

interface State {
    geo: Geo | null
    currentLocation: Location | null
    locations: Location[]
    slideOverIsOpen: boolean
    browserCoordinatesIsLoading: boolean
}
