import { default as _91_46_46_46slug_93jihHgADkM6Meta } from "/Users/chris/Projects/autoz/dealerme/pages/[...slug].vue?macro=true";
import { default as _91slug_93uSVKqBxELMMeta } from "/Users/chris/Projects/autoz/dealerme/pages/blog/[slug].vue?macro=true";
import { default as index6a5DzFayncMeta } from "/Users/chris/Projects/autoz/dealerme/pages/blog/index.vue?macro=true";
import { default as indexKSCvbK59qPMeta } from "/Users/chris/Projects/autoz/dealerme/pages/careers/index.vue?macro=true";
import { default as contactNPayJp1nRhMeta } from "/Users/chris/Projects/autoz/dealerme/pages/contact.vue?macro=true";
import { default as indextbqn8liPXdMeta } from "/Users/chris/Projects/autoz/dealerme/pages/finance-application/index.vue?macro=true";
import { default as _91slug_93W0zj5tY6f1Meta } from "/Users/chris/Projects/autoz/dealerme/pages/for-sale/[slug].vue?macro=true";
import { default as compareuvQiBuxrpmMeta } from "/Users/chris/Projects/autoz/dealerme/pages/inventory/compare.vue?macro=true";
import { default as index2EDeIUFcKtMeta } from "/Users/chris/Projects/autoz/dealerme/pages/inventory/index.vue?macro=true";
import { default as _91slug_93E4D4sgERdHMeta } from "/Users/chris/Projects/autoz/dealerme/pages/locations/[slug].vue?macro=true";
import { default as indexDbrOaK4FFkMeta } from "/Users/chris/Projects/autoz/dealerme/pages/locations/index.vue?macro=true";
import { default as loginrw3mdq98iBMeta } from "/Users/chris/Projects/autoz/dealerme/pages/login.vue?macro=true";
import { default as _91_46_46_46slug_93ghJsChLlUHMeta } from "/Users/chris/Projects/autoz/dealerme/pages/lp/[...slug].vue?macro=true";
import { default as personal_45consumer_45informationv84mfbD4TGMeta } from "/Users/chris/Projects/autoz/dealerme/pages/personal-consumer-information.vue?macro=true";
import { default as privacy_45policyr2H2mYcvWmMeta } from "/Users/chris/Projects/autoz/dealerme/pages/privacy-policy.vue?macro=true";
import { default as privacy_45requestsAhKFfEDb6IMeta } from "/Users/chris/Projects/autoz/dealerme/pages/privacy-requests.vue?macro=true";
import { default as scheduleLu5ijwqouoMeta } from "/Users/chris/Projects/autoz/dealerme/pages/service/schedule.vue?macro=true";
export default [
  {
    name: "slug___en",
    path: "/:slug(.*)*",
    component: () => import("/Users/chris/Projects/autoz/dealerme/pages/[...slug].vue")
  },
  {
    name: "slug___es",
    path: "/es/:slug(.*)*",
    component: () => import("/Users/chris/Projects/autoz/dealerme/pages/[...slug].vue")
  },
  {
    name: "blog-slug___en",
    path: "/blog/:slug()",
    component: () => import("/Users/chris/Projects/autoz/dealerme/pages/blog/[slug].vue")
  },
  {
    name: "blog-slug___es",
    path: "/es/blog/:slug()",
    component: () => import("/Users/chris/Projects/autoz/dealerme/pages/blog/[slug].vue")
  },
  {
    name: "blog___en",
    path: "/blog",
    component: () => import("/Users/chris/Projects/autoz/dealerme/pages/blog/index.vue")
  },
  {
    name: "blog___es",
    path: "/es/blog",
    component: () => import("/Users/chris/Projects/autoz/dealerme/pages/blog/index.vue")
  },
  {
    name: "careers___en",
    path: "/careers",
    component: () => import("/Users/chris/Projects/autoz/dealerme/pages/careers/index.vue")
  },
  {
    name: "careers___es",
    path: "/es/careers",
    component: () => import("/Users/chris/Projects/autoz/dealerme/pages/careers/index.vue")
  },
  {
    name: "contact___en",
    path: "/contact",
    component: () => import("/Users/chris/Projects/autoz/dealerme/pages/contact.vue")
  },
  {
    name: "contact___es",
    path: "/es/contact",
    component: () => import("/Users/chris/Projects/autoz/dealerme/pages/contact.vue")
  },
  {
    name: "finance-application___en",
    path: "/finance-application",
    component: () => import("/Users/chris/Projects/autoz/dealerme/pages/finance-application/index.vue")
  },
  {
    name: "finance-application___es",
    path: "/es/finance-application",
    component: () => import("/Users/chris/Projects/autoz/dealerme/pages/finance-application/index.vue")
  },
  {
    name: "for-sale-slug___en",
    path: "/for-sale/:slug()",
    component: () => import("/Users/chris/Projects/autoz/dealerme/pages/for-sale/[slug].vue")
  },
  {
    name: "for-sale-slug___es",
    path: "/es/for-sale/:slug()",
    component: () => import("/Users/chris/Projects/autoz/dealerme/pages/for-sale/[slug].vue")
  },
  {
    name: "inventory-compare___en",
    path: "/inventory/compare",
    component: () => import("/Users/chris/Projects/autoz/dealerme/pages/inventory/compare.vue")
  },
  {
    name: "inventory-compare___es",
    path: "/es/inventory/compare",
    component: () => import("/Users/chris/Projects/autoz/dealerme/pages/inventory/compare.vue")
  },
  {
    name: "inventory___en",
    path: "/inventory",
    component: () => import("/Users/chris/Projects/autoz/dealerme/pages/inventory/index.vue")
  },
  {
    name: "inventory___es",
    path: "/es/inventory",
    component: () => import("/Users/chris/Projects/autoz/dealerme/pages/inventory/index.vue")
  },
  {
    name: "locations-slug___en",
    path: "/locations/:slug()",
    component: () => import("/Users/chris/Projects/autoz/dealerme/pages/locations/[slug].vue")
  },
  {
    name: "locations-slug___es",
    path: "/es/locations/:slug()",
    component: () => import("/Users/chris/Projects/autoz/dealerme/pages/locations/[slug].vue")
  },
  {
    name: "locations___en",
    path: "/locations",
    component: () => import("/Users/chris/Projects/autoz/dealerme/pages/locations/index.vue")
  },
  {
    name: "locations___es",
    path: "/es/locations",
    component: () => import("/Users/chris/Projects/autoz/dealerme/pages/locations/index.vue")
  },
  {
    name: "login___en",
    path: "/login",
    component: () => import("/Users/chris/Projects/autoz/dealerme/pages/login.vue")
  },
  {
    name: "login___es",
    path: "/es/login",
    component: () => import("/Users/chris/Projects/autoz/dealerme/pages/login.vue")
  },
  {
    name: "lp-slug___en",
    path: "/lp/:slug(.*)*",
    meta: _91_46_46_46slug_93ghJsChLlUHMeta || {},
    component: () => import("/Users/chris/Projects/autoz/dealerme/pages/lp/[...slug].vue")
  },
  {
    name: "lp-slug___es",
    path: "/es/lp/:slug(.*)*",
    meta: _91_46_46_46slug_93ghJsChLlUHMeta || {},
    component: () => import("/Users/chris/Projects/autoz/dealerme/pages/lp/[...slug].vue")
  },
  {
    name: "personal-consumer-information___en",
    path: "/personal-consumer-information",
    component: () => import("/Users/chris/Projects/autoz/dealerme/pages/personal-consumer-information.vue")
  },
  {
    name: "personal-consumer-information___es",
    path: "/es/personal-consumer-information",
    component: () => import("/Users/chris/Projects/autoz/dealerme/pages/personal-consumer-information.vue")
  },
  {
    name: "privacy-policy___en",
    path: "/privacy-policy",
    component: () => import("/Users/chris/Projects/autoz/dealerme/pages/privacy-policy.vue")
  },
  {
    name: "privacy-policy___es",
    path: "/es/privacy-policy",
    component: () => import("/Users/chris/Projects/autoz/dealerme/pages/privacy-policy.vue")
  },
  {
    name: "privacy-requests___en",
    path: "/privacy-requests",
    component: () => import("/Users/chris/Projects/autoz/dealerme/pages/privacy-requests.vue")
  },
  {
    name: "privacy-requests___es",
    path: "/es/privacy-requests",
    component: () => import("/Users/chris/Projects/autoz/dealerme/pages/privacy-requests.vue")
  },
  {
    name: "service-schedule___en",
    path: "/service/schedule",
    component: () => import("/Users/chris/Projects/autoz/dealerme/pages/service/schedule.vue")
  },
  {
    name: "service-schedule___es",
    path: "/es/service/schedule",
    component: () => import("/Users/chris/Projects/autoz/dealerme/pages/service/schedule.vue")
  },
  {
    name: "inventory-make-body-style",
    path: "/inventory/:makeOrBodyStyle",
    component: () => import("/Users/chris/Projects/autoz/dealerme/pages/inventory/index.vue")
  },
  {
    name: "category",
    path: "/:category([\\w-_]+-for-sale)",
    component: () => import("/Users/chris/Projects/autoz/dealerme/pages/inventory/index.vue")
  },
  {
    name: "category-make-body-style",
    path: "/:category([\\w-_]+-for-sale)/:makeOrBodyStyle",
    component: () => import("/Users/chris/Projects/autoz/dealerme/pages/inventory/index.vue")
  },
  {
    name: "storyblok-page",
    path: "/pages/:slug",
    component: () => import("/Users/chris/Projects/autoz/dealerme/pages/[...slug].vue")
  }
]