import { defineStore } from 'pinia'
import { ModalSize } from '~/utils/types/global/enums'

export const useModalStore = defineStore('modal', {
    state: (): State => ({
        open: false,
        title: '',
        showCloseButton: true,
        component: null,
        size: null,
        parameters: {},
    }),
    getters: {
        getOpen(state): boolean {
            return state.open
        },
    },
    actions: {
        openModal(params: ModalParams = {} as ModalParams): void {
            if (this.component === null) {
                this.open = true
                this.title = params.title
                this.component = params.component

                if (params.params !== undefined) {
                    this.parameters = params.params
                }

                if (params.showCloseButton !== undefined) {
                    this.showCloseButton = params.showCloseButton
                }

                this.size = params.size ?? ModalSize.XL
            }
        },
        closeModal(): void {
            this.open = false

            // reset actions
            if (this.component === 'DepositModal') {
                useDepositStore().reset()
            }

            setTimeout(() => this.resetModal(), 500)
        },
        resetModal() {
            this.title = ''
            this.component = null
            this.parameters = null
        },
    },
})

interface State {
    open: boolean
    title: string
    showCloseButton: boolean
    component: string | null
    size: ModalSize | null
    parameters: Record<string, any> | null
}

interface ModalParams {
    title: string
    component: string
    params?: Record<string, any> | null
    showCloseButton?: boolean
    size?: ModalSize
}
