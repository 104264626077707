import { defineNuxtPlugin } from '#app/nuxt'
import { LazyAccordion, LazyAccordionBlok, LazyCenteredAccordion, LazyBlogArticles1, LazyBodyStyleBar, LazyCallToAction, LazyCallToActionBackgroundImage, LazyCallToActionImage, LazyCallToActionOverlap, LazyElfsightApplication, LazyFeature1, LazyFeature2, LazyFlexPortraitVideo, LazyFlexVideo, LazyFullWidthBlok, LazyGrid, LazyGridImage, LazyGridRichText, LazyInventoryBar, LazyLargeVideo, LazyLogoCloud, LazyLogoCloudItem, LazyMakeSlider, LazyRichText, LazySalespersonOfTheMonth, LazySearchBar, LazyTestimonials1, LazyVideo1, LazyYoutubeVideo, LazyFullwidthhero1, LazyHero1, LazyHero2, LazyTabColumns, LazyTabImageGrid, LazyTabLink, LazyTabSimple, LazyPage, LazySellHeader, LazyCapitalOneCalculator, LazyCapitalOneLeadsNavigator, LazyCapitalOnePrequalification, LazyCapitalOneShowroom, LazyEdmundsTradeIn, LazyEdmundsTradeInWidget, LazyEdmundsTradeInWidgetSlim, LazyIpacketButton, LazySvgoFacebook, LazySvgoInstagram, LazySvgoLeaf, LazySvgoPlug, LazySvgoX, LazySvgoYoutube } from '#components'
const lazyGlobalComponents = [
  ["Accordion", LazyAccordion],
["AccordionBlok", LazyAccordionBlok],
["CenteredAccordion", LazyCenteredAccordion],
["BlogArticles1", LazyBlogArticles1],
["BodyStyleBar", LazyBodyStyleBar],
["CallToAction", LazyCallToAction],
["CallToActionBackgroundImage", LazyCallToActionBackgroundImage],
["CallToActionImage", LazyCallToActionImage],
["CallToActionOverlap", LazyCallToActionOverlap],
["ElfsightApplication", LazyElfsightApplication],
["Feature1", LazyFeature1],
["Feature2", LazyFeature2],
["FlexPortraitVideo", LazyFlexPortraitVideo],
["FlexVideo", LazyFlexVideo],
["FullWidthBlok", LazyFullWidthBlok],
["Grid", LazyGrid],
["GridImage", LazyGridImage],
["GridRichText", LazyGridRichText],
["InventoryBar", LazyInventoryBar],
["LargeVideo", LazyLargeVideo],
["LogoCloud", LazyLogoCloud],
["LogoCloudItem", LazyLogoCloudItem],
["MakeSlider", LazyMakeSlider],
["RichText", LazyRichText],
["SalespersonOfTheMonth", LazySalespersonOfTheMonth],
["SearchBar", LazySearchBar],
["Testimonials1", LazyTestimonials1],
["Video1", LazyVideo1],
["YoutubeVideo", LazyYoutubeVideo],
["Fullwidthhero1", LazyFullwidthhero1],
["Hero1", LazyHero1],
["Hero2", LazyHero2],
["TabColumns", LazyTabColumns],
["TabImageGrid", LazyTabImageGrid],
["TabLink", LazyTabLink],
["TabSimple", LazyTabSimple],
["Page", LazyPage],
["SellHeader", LazySellHeader],
["CapitalOneCalculator", LazyCapitalOneCalculator],
["CapitalOneLeadsNavigator", LazyCapitalOneLeadsNavigator],
["CapitalOnePrequalification", LazyCapitalOnePrequalification],
["CapitalOneShowroom", LazyCapitalOneShowroom],
["EdmundsTradeIn", LazyEdmundsTradeIn],
["EdmundsTradeInWidget", LazyEdmundsTradeInWidget],
["EdmundsTradeInWidgetSlim", LazyEdmundsTradeInWidgetSlim],
["IpacketButton", LazyIpacketButton],
["SvgoFacebook", LazySvgoFacebook],
["SvgoInstagram", LazySvgoInstagram],
["SvgoLeaf", LazySvgoLeaf],
["SvgoPlug", LazySvgoPlug],
["SvgoX", LazySvgoX],
["SvgoYoutube", LazySvgoYoutube],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
