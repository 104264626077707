import { ASCEvent } from '~/utils/types/asc/enums'

export const mapAscEventToIgluSchema = (
    event: ASCEvent,
    data: Record<string, unknown>,
): IgluSchemaData | null => {
    // const schemaStartUrl: string =
    //     'https://sc-metadata-production.s3-us-west-2.amazonaws.com/schemas/'
    const schemaStartUrl: string = 'iglu:'

    const igluSchema: Record<string, IgluSchemaItem> = {
        [ASCEvent.CTA_INTERACTION]: {
            schema: 'com.dealerme/asc_cta_interaction/jsonschema/1-0-0',
            allowedFields: [
                'event_owner',
                'page_type',
                'element_text',
                'element_color',
                'element_order',
                'element_type',
                'element_subtype',
                'event_action',
                'event_action_result',
                'link_url',
                'item_id',
                'item_number',
                'item_price',
                'item_condition',
                'item_year',
                'item_make',
                'item_model',
                'item_variant',
                'item_color',
                'item_type',
                'item_fuel_type',
                'item_inventory_date',
                'item_location',
                'item_status',
            ],
        },
        [ASCEvent.ELEMENT_CONFIGURATION]: {
            schema: 'com.dealerme/asc_element_configuration/jsonschema/1-0-0',
            allowedFields: [
                'event_owner',
                'page_type',
                'event_action_result',
                'event_action',
                'element_state',
                'element_type',
                'element_subtype',
                'element_title',
                'element_text',
                'element_value',
                'element_color',
                'element_position',
                'element_order',
            ],
        },
        [ASCEvent.CLICK_TO_CALL]: {
            schema: 'com.dealerme/asc_click_to_call/jsonschema/1-0-0',
            allowedFields: [
                'event_owner',
                'page_type',
                'comm_phone_number',
                'department',
                'item_id',
                'item_number',
                'item_price',
                'item_condition',
                'item_year',
                'item_make',
                'item_model',
                'item_variant',
                'item_color',
                'item_type',
                'item_fuel_type',
                'item_inventory_date',
                'item_location',
                'item_status',
            ],
        },
        [ASCEvent.ITEM_PAGEVIEW]: {
            schema: 'com.dealerme/asc_item_pageview/jsonschema/1-0-1',
            allowedFields: [
                'event_owner',
                'page_type',
                'item_id',
                'item_number',
                'item_price',
                'item_condition',
                'item_year',
                'item_make',
                'item_model',
                'item_variant',
                'item_color',
                'item_type',
                'item_fuel_type',
                'item_inventory_date',
                'item_location',
                'item_status',
                'item_results',
            ],
        },
        [ASCEvent.ITEMLIST_PAGEVIEW]: {
            schema: 'com.dealerme/asc_itemlist_pageview/jsonschema/1-0-0',
            allowedFields: ['event_owner', 'page_type', 'item_results'],
        },
        [ASCEvent.RETAIL_PROCESS]: {
            schema: 'com.dealerme/asc_retail_process/jsonschema/1-0-0',
            allowedFields: [
                'event_owner',
                'page_type',
                'flow_name',
                'element_title',
                'flow_outcome',
                'flow_stage',
                'item_id',
                'item_number',
                'item_price',
                'item_condition',
                'item_year',
                'item_make',
                'item_model',
                'item_variant',
                'item_color',
                'item_type',
                'item_fuel_type',
                'item_inventory_date',
                'item_location',
                'item_status',
            ],
        },
    }

    const schemaItem: IgluSchemaItem | null = igluSchema[event] ?? null

    if (schemaItem === null) {
        return null
    }

    if (schemaItem.allowedFields.length > 0) {
        data = Object.keys(data)
            .filter((key) => schemaItem.allowedFields.includes(key))
            .reduce((obj, key) => {
                return {
                    ...obj,
                    [key]: data[key],
                }
            }, {})
    }

    return { schema: `${schemaStartUrl}${schemaItem.schema}`, data }
}

interface IgluSchemaItem {
    schema: string
    allowedFields: string[]
}

export interface IgluSchemaData {
    schema: string
    data: Record<string, unknown>
}
