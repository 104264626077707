import { defineStore } from 'pinia'
import { load as loadFingerprintJS } from '@fingerprintjs/fingerprintjs'
import { newTracker, setUserId, trackPageView } from '@snowplow/browser-tracker'
import type { Fingerprint } from '~/utils/types/fingerprint'
import type { ClientCFData } from '~/utils/types/visitor'

export const useVisitorStore = defineStore('visitor', {
    state: (): State => ({
        fingerprint: null,
        cfData: null,
        leadFormSubmitted: false,
    }),
    persist: {
        pick: ['fingerprint'],
    },
    getters: {
        getVisitorId(state) {
            // TODO - anonymous-orgId-visitorId
            return state.fingerprint?.visitorId
        },
    },
    actions: {
        initializeFingerprintAndSnowplow() {
            if (this.fingerprint === null) {
                const fpPromise = loadFingerprintJS()

                fpPromise
                    .then((fp) => fp.get())
                    .then((result: any) => {
                        this.fingerprint = result

                        if (useRuntimeConfig().public.env === 'production') {
                            this.initializeSnowplow()
                        }
                    })
            }
        },
        async addFingerprintToQuery(params: Record<string, any>) {
            const locationStore = useLocationStore()

            if (locationStore.geo === null || this.fingerprint === null) {
                await this.waitForGeoAndFingerprint(locationStore)
            }

            return Object.assign(JSON.parse(JSON.stringify(params)), {
                visitor_id: this.getVisitorId,
                coordinates: locationStore.getCurrentLocationCoordinatesString,
                radius: null,
                precision: null,
            })
        },
        async waitForGeoAndFingerprint(locationStore: any) {
            const startTime = new Date().getTime()

            while (true) {
                if (locationStore.geo !== null && this.fingerprint !== null) {
                    break
                } else if (new Date().getTime() > startTime + 3000) {
                    break
                }

                await new Promise((resolve) => setTimeout(resolve, 500))
            }
        },
        setClientCFData(data: ClientCFData) {
            this.cfData = data
        },
        visitorSubmittedLeadForm() {
            if (!this.leadFormSubmitted) {
                this.leadFormSubmitted = true

                const storyblokStore = useStoryblokStore()

                // check if gating enabled
                if (storyblokStore.gatingEnabled) {
                    storyblokStore.openGate()
                }
            }
        },
        initializeSnowplow() {
            const domain: string = useDomainStore().domain.domain
            const visitorStore = useVisitorStore()

            newTracker('spc', domain, {
                appId: domain,
                discoverRootDomain: true,
                postPath: '/spc', // Collector path override
                cookieSameSite: 'Lax', // Recommended
                eventMethod: 'post',
                bufferSize: 1,
                cookieLifetime: 63072000,
                stateStorageStrategy: 'cookieAndLocalStorage',
                contexts: {
                    webPage: true,
                },
            })

            const visitorId: string | null = visitorStore.getVisitorId ?? null

            if (visitorId) {
                setUserId(visitorId)
            }

            // enableActivityTracking({
            //     minimumVisitLength: 30,
            //     heartbeatDelay: 10,
            // })

            trackPageView()
        },
    },
})

interface State {
    fingerprint: Fingerprint | null
    cfData: ClientCFData | null
    leadFormSubmitted: boolean
}
